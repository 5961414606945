html,
body {
  font-family: sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 25px;
  margin-bottom: 3px;
}
a {
  text-decoration: none;
}
.version {
  margin-top: 10px;
}
.hidden {
  display: none;
}
.extra {
  margin-top: 5px;
}
.extra span {
  color: green;
  cursor: pointer;
}
